import { Link } from "gatsby";
import React from "react";
import { cn } from "../lib/helpers";

import * as styles from "./back-button.module.css";

const BackButton = () => (
  <div className={cn(styles.root)}>
    <Link to="/">← Zpět na homepage</Link>
  </div>
);

export default BackButton;
