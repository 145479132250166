import { Link } from "gatsby";
import React from "react";
import { cn } from "../lib/helpers";

import * as styles from "./rules.module.css";
import * as commonStyles from "./students.module.css";

const Rules = () => (
  <div className={cn(commonStyles.layout, styles.root)}>
    <div className={commonStyles.container}>
      <h1>{"Návštěvní řád"}</h1>
      <h3>{"1. ÚVODNÍ USTANOVENÍ"}</h3>

      <p>{`1.1 Tento návštěvní řád je směrnicí vydávanou společností
ArtCulture, spol. s r.o. IČ: 078 55 206, se sídlem Kořenského
905/4, Střekov, 400 03 Ústí nad Labem, zapsanou v
obchodním rejstříku vedeném Krajským soudem v Ústí nad
Labem, oddíl C, vložka 43116 (dále jen „provozovatel“ nebo
„pořadatel“), která určuje základní závazná pravidla osob
vstupujících do prostor areálu festivalu KVĚTENLES 2022 a
využívajících zařízení umístěná v areálu festivalu
KVĚTENLES 2022 (dále jen „návštěvní řád“).
`}</p>
      <p>{`1.2 Účelem návštěvního řádu je zejména zajištění ochrany
bezpečnosti, zajištění požární ochrany, zajištění hygieny,
zajištění ochrany zdraví a majetku na festivalu KVĚTENLES
2022 a rovněž zabezpečení ideálních podmínek průběhu
všech akcí (dále jen „akce“), které budou součástí festivalu
KVĚTENLES 2022. Cílem návštěvního řádu je též zajištění
příjemné společenské atmosféry v areálu festivalu
KVĚTENLES 2022.
`}</p>
      <p>{`1.3 Areálem festivalu KVĚTENLES 2021 se pro účely tohoto
návštěvního řádu rozumí viditelně ohraničené prostory
provozovatele u chomutovského městského parku.
Pro veřejnost bude areál festivalu KVĚTENLES 2022 otevřen
28.05.2022 od 10:00 do 24:00.
`}</p>
      <p>{`1.4 Areál festivalu KVĚTENLES 2022 je viditelně ohraničen
plotem a vstup do areálu je možný pouze s platnou
vstupenkou nebo jiným označením určeným provozovatelem.
Zakoupením vstupenky a/nebo předložením vstupenky při
vstupu do areálu a/nebo vstupem na akci do areálu festivalu
KVĚTENLES 2022 vyjadřuje každý návštěvník souhlas s
podmínkami stanovenými v tomto návštěvním řádu a
okamžikem vstupu do areálu festivalu KVĚTENLES 2022
tento návštěvní řád stává pro každého návštěvníka
závazným, a to po celou dobu pobytu v něm.
Návštěvníkem a/nebo veřejností se pro účely tohoto
návštěvního řádu rozumí každá osoba vstupující do areálu
festivalu KVĚTENLES 2022, a to včetně zástupců médií a
technického personálu.
`}</p>
      <p>{`1.5 Ustanovení tohoto návštěvního řádu se použijí obdobně i
na osoby vstupující do areálu festivalu KVĚTENLES 2022 na
základě zvláštního smluvního vztahu s provozovatelem
a/nebo jeho smluvními partnery.`}</p>

      <h3>{"2. AREÁL FESTIVALU KVĚTENLES 2022"}</h3>
      <p>{`2.1 Zakoupením vstupenky je uzavřena mezi návštěvníkem a
pořadatelem festivalu ArtCulture, spol. s r.o., sídlem
Kořenského 905/4, Střekov 400 03 Ústí nad Labem, IČ:
07855206, smlouva o účasti na festivalu KVĚTENLES. Každý
návštěvník festivalu je povinen vstupenku po předložení
uschovat tak, aby ji při opuštění a následném vrácení do
areálu mohl znovu ukázat. Vstupenkou se rozumí jak tištěná
vstupenka umožňující vstup na festival blíže neurčené osobě,
tak elektronická vstupenka (tzv. e-ticket) umožňující vstup na
festival konkrétní osobě.
Každá osoba v areálu festivalu KVĚTENLES 2022 je povinna
po celou dobu svého pobytu v areálu mít u sebe vstupenku
a/nebo jiný doklad opravňující ji k pobytu v areálu, a na
žádost pracovníka pořadatele a/nebo pořadatelem pověřené
osoby a/nebo bezpečnostní služby bez prodlení prokázat své
oprávnění k pobytu v areálu.
Každý, kdo nebude schopen prokázat své oprávnění k
pobytu v areálu festivalu KVĚTENLES 2022, nemusí být do
areálu festivalu KVĚTENLES 2022 vpuštěn a/nebo může být
z prostor areálu festivalu KVĚTENLES 2022 vykázán.
Návštěvníci a veřejnost může do areálu festivalu
KVĚTENLES 2022, resp. do jeho příslušně vymezených zón,
vstupovat pouze se souhlasem provozovatele, pouze v
návštěvních hodinách a pouze v souladu s podmínkami
stanovenými v tomto návštěvním řádu. Jakékoliv reklamace
je možné uplatnit pouze před vstupem do areálu.
`}</p>
      <p>{`2.2 Dozor nad dodržováním a respektováním tohoto
návštěvního řádu v areálu festivalu KVĚTENLES 2022
provádí řádně označení pracovníci provozovatele a další k
tomu provozovatelem pověřené a řádně označené osoby a v
rozsahu svých zákonných pravomocí rovněž Policie České
republiky a Městská policie Chomutov.
`}</p>
      <p>{`2.3 Osoby, které jsou pod vlivem alkoholu nebo jiných
návykových a omamných látek, stejně tak jako osoby, které
svým chováním ohrožují bezpečnost a/nebo zdraví osob
a/nebo majetku nacházejícího se v areálu festivalu
KVĚTENLES 2022, pozbývají oprávnění k pobytu v areálu
festivalu KVĚTENLES 2022 a nemusí být do areálu festivalu
KVĚTENLES 2022 vpuštěny a/nebo mohou být pracovníkem
pořadatele a/nebo pořadatelem pověřenou osobou a/nebo
bezpečnostní službou z areálu festivalu KVĚTENLES 2022
vykázány.
Stejně tak nemusí být do areálu festivalu KVĚTENLES 2022
vpuštěny a/nebo mohou být z areálu festivalu KVĚTENLES
2022 vykázány osoby, které porušují pravidla stanovená v
tomto návštěvním řádu a/nebo osoby, které nerespektují
pokyny pracovníků pořadatele a/nebo pořadatelem
pověřených osob.
`}</p>
      <p>{`2.4 Osoby, které nebudou za podmínek dle tohoto
návštěvního řádu do areálu festivalu KVĚTENLES 2022
vpuštěny a/nebo budou z areálu festivalu KVĚTENLES 2022
vykázány, nemají nárok na náhradu uhrazeného vstupného či
jiných nákladů vynaložených na vstup a účast na festivalu
KVĚTENLES 2022.
Osobám uvedeným v tomto ustanovení může být
pořadatelem zároveň omezen či zcela zakázán vstup do
areálu festivalu KVĚTENLES 2022.
`}</p>
      <p>{`2.5 V areálu festivalu KVĚTENLES 2022 je zakázán prodej
tabákových a alkoholických výrobků osobám mladším 18 let.`}</p>
      <h3>{"3. POVINNOSTI NÁVŠTĚVNÍKŮ"}</h3>
      <p>{`3.1 Návštěvníci festivalu KVĚTENLES 2022 jsou povinni
chovat se v jeho areálu tak, aby svým jednáním neohrožovali
bezpečnost a/nebo zdraví třetích osob a/nebo jejich majetek,
stejně tak jako zařízení umístěná v areálu festivalu
KVĚTENLES 2022, a dále svým chováním neomezovat,
neohrožovat či neobtěžovat ostatní návštěvníky nad míru
odpovídající z okolností vyplývajících z průběhu festivalu
KVĚTENLES 2022.
`}</p>
      <p>{`3.2 Návštěvníci festivalu KVĚTENLES 2022 jsou povinni
dodržovat pokyny a nařízení pracovníků pořadatele a/nebo
osob pověřených pořadatelem a/nebo bezpečnostní služby,
jakož i pokyny a nařízení Policie České republiky, Městské
policie Chomutov, Hasičského záchranného sboru Ústeckého
kraje a Zdravotnické záchranné služby Českého Červeného
kříže.
`}</p>
      <p>{`3.3 Návštěvníci festivalu KVĚTENLES 2022 jsou povinni
respektovat pravidla vyplývající z tohoto návštěvního řádu,
obecně závazných právních předpisů, jakož i pravidla
slušného chování a dobrých mravů.
`}</p>
      <p>{`3.4 Návštěvníci festivalu KVĚTENLES 2022 mají zakázáno
vnášet do areálu festivalu KVĚTENLES 2022 – zejména
následující předměty:
`}</p>
      <p>{`a) rasistické, hanlivé, vulgární, či jiné materiály odporující
dobrým mravům;
`}</p>
      <p>{`b) zbraně jakéhokoliv druhu, jakož i všechny předměty
použitelné jako sečné, bodné, či úderové zbraně či jiné
předměty, které by mohly ohrozit bezpečnost, zdraví či život
návštěvníků (např. nůžky apod.);
`}</p>
      <p>{`c) spreje, leptavé, hořlavé (např. parfémy v obalu nad 200
ml), výbušné, barvící, nebo jiné látky ohrožující zdraví;
`}</p>
      <p>{`d) láhve, nádoby, plechovky nebo jiné předměty z lámavých,
z tříštivých nebo výjimečně tvrdých materiálů;
`}</p>
      <p>{`e) ohňostroj/rachejtle, světlice, dýmovnice a ostatní
pyrotechnické předměty nebo odpalovací zařízení;
`}</p>
      <p>{`f) alkoholické nápoje a/nebo návykové a omamné látky;
`}</p>
      <p>{`g) zvířata s výjimkou asistenčních psů pro tělesně postižené
osoby;
`}</p>
      <p>{`h) materiály propagující politickou stranu nebo hnutí, či
jakékoliv politicky zaměřené materiály;
`}</p>
      <p>{`i) nadměrná batožina a zavazadla, jízdní kola;
`}</p>
      <p>{`j) deštníky, psychotropní látky,
`}</p>
      <p>{`k) laserová ukazovátka,
`}</p>
      <p>{`l) nahrávací zařízení, filmové kamery a videokamery,
profesionální a poloprofesionální fotoaparáty a zrcadlovky.
`}</p>
      <p>{`O nebezpečnosti a/nebo nevhodnosti jednotlivých předmětů
vnášených návštěvníkem do areálu festivalu KVĚTENLES
2022 má právo rozhodnout provozovatel nebo jím pověřené
osoby, a to zejména vedoucí pracovník bezpečnostní služby
(Supervisor) a/nebo jemu nadřízené osoby. Kontrola může
být provedena při vstupu do areálu, popř. i kdykoliv po vstupu
návštěvníka do areálu festivalu KVĚTENLES 2022.
Návštěvníci, kteří u sebe budou mít nevhodné předměty nebo
nadměrnou batožinu nebudou do areálu festivalu
KVĚTENLES 2022 vpuštěni.
`}</p>
      <p>{`3.5 Návštěvníci festivalu KVĚTENLES 2022 jsou povinni
umožnit pracovníkům provozovatele a/nebo provozovatelem
pověřeným osobám provést prohlídku vnášených předmětů a
zavazadel za účelem kontroly dodržování předchozího
ustanovení.
Odmítne-li návštěvník provedení prohlídky dle tohoto
ustanovení, nemusí být do areálu festivalu KVĚTENLES
2022 vpuštěn a/nebo může být z areálu festivalu
KVĚTENLES 2022 vykázán.
`}</p>
      <p>{`3.6 Návštěvníci festivalu KVĚTENLES 2022 souhlasí s
provedením bezpečnostní prohlídky za účelem kontroly
dodržování tohoto návštěvního řádu, a to jak prohlídky
zavazadel, tak osobní prohlídky pracovníky provozovatele
a/nebo provozovatelem pověřenými osobami a/nebo
bezpečnostní službou, při vstupu do areálu festivalu
KVĚTENLES 2022.
Odmítne-li návštěvník provedení bezpečnostní prohlídky, pak
nemusí být do areálu festivalu KVĚTENLES 2022 vpuštěn
a/nebo může být z areálu festivalu KVĚTENLES 2022
vykázán.
`}</p>
      <p>{`3.7 Návštěvníkům festivalu KVĚTENLES 2022 je dále
zakázáno:
`}</p>
      <p>{`a) projevovat, rozšiřovat či veřejně propagovat rasistické,
hanlivé, vulgární nebo dobrým mravům odporující slogany či
zobrazení a/nebo k takovému jednání jiné podněcovat;
`}</p>
      <p>{`b) propagovat jakoukoliv politickou stranu nebo hnutí, či
výrobek nebo službu;
`}</p>
      <p>{`c) přelézat či vstupovat na jakékoliv stavby a zařízení v
areálu, a to zejména fasády, ploty, zdi, zábrany, osvětlovací
zařízení, stromy, sloupy, střechy, mantinely, podpůrné
konstrukce apod.;
`}</p>
      <p>{`d) vstupovat do oblastí, které nejsou určeny pro veřejnost a
jsou pro tyto účely jako nepřístupné označeny;
`}</p>
      <p>{`e) vhazovat jakékoliv předměty do prostor, v nichž se
pohybují návštěvníci, a to zejména do prostor sportovních
ploch, jevišť a hledišť;
`}</p>
      <p>{`f) pořizovat v areálu festivalu KVĚTENLES 2022 jakékoliv
zvukové či obrazové záznamy pro komerční účely či
fotografování veškerých vystoupení profesionálními a
poloprofesionálními fotoaparáty a zrcadlovkami bez
předchozího písemného souhlasu provozovatele;
`}</p>
      <p>{`g) kouřit v prostorách vyznačených provozovatelem;
`}</p>
      <p>{`h) vnášet nápoje do areálu festivalu KVĚTENLES 2022;
`}</p>
      <p>{`i) zakládat oheň, zapalovat nebo odstřelovat jakoukoliv
pyrotechniku;
`}</p>
      <p>{`j) svévolně přemisťovat, či jiným způsobem manipulovat se
zařízením a/nebo vybavením umístěným v areálu;
`}</p>
      <p>{`k) Jakýmkoliv způsobem manipulovat s rozvody vody,
elektrické energie, vzduchu, či jiným zařízením v areálu;
`}</p>
      <p>{`l) ničit jakékoliv zařízení a vybavení umístěné v areálu, a to
včetně parkových dřevin a travin;
`}</p>
      <p>{`m) popisovat, polepovat nebo pomalovávat jakékoliv zařízení,
vybavení nebo komunikace v areálu;
`}</p>
      <p>{`n) konat tělesné potřeby mimo toalety, či jakýmkoliv jiným
způsobem znečišťovat areál;
`}</p>
      <p>{`o) vjíždět do areálu bez předchozího souhlasu, popř.
instrukce provozovatele a/nebo jím pověřené osoby;
`}</p>
      <p>{`p) vyhrožovat násilím a/nebo se jej dopustit vůči jednotlivci
a/nebo skupině osob a/nebo k takovému jednání jiné
podněcovat;
`}</p>
      <p>{`q) bez povolení prodávat vstupenky a jakékoliv zboží,
`}</p>
      <p>{`r) rozdávat či umisťovat ve festivalovém areálu jakékoli
propagační a reklamní materiály bez písemného povolení
pořadatele!
`}</p>
      <p>{`s) vstupu se psy a jinými zvířaty!
`}</p>
      <p>{`3.8 Návštěvníci nejsou oprávněni bez předchozího
písemného souhlasu provozovatele a/nebo v rozporu s
podmínkami uděleného písemného souhlasu provozovatele
vykonávat v areálu festivalu KVĚTENLES 2022 jakoukoliv
podnikatelskou nebo obdobnou činnost, či ostatním
návštěvníkům rozdávat a/nebo prodávat jakékoliv předměty,
a to včetně novin, časopisů, tiskovin, reklamních prospektů
apod., stejně jako v areálu festivalu KVĚTENLES 2022
skladovat jakékoliv předměty.
`}</p>
      <p>{`3.9 Účastník festivalu se účastní festivalu výlučně na vlastní
odpovědnost, odpovídá za veškerou škodu, kterou způsobí,
bere na vědomí, že při ztrátě vstupenky se stává smlouva
neplatnou a není oprávněn se domáhat vstupu na festival.
`}</p>
      <p>{`3.10 Účastník festivalu se zavazuje v rámci bezpečnosti
`}</p>
      <p>{`a) při zdolávání případného požáru, živelné pohromy nebo
jiné mimořádné události poskytnout přiměřenou osobní
pomoc, nevystaví-li tím vážnému nebezpečí nebo ohrožení
sebe nebo osoby blízké anebo nebrání-li mu v tom důležitá
okolnost, a potřebnou věcnou pomoc.
`}</p>
      <p>{`b) popel a nedopalky od cigaret a ostatních tabákových
výrobků odhazovat pouze do určených nádob. Dbát na
bezpečnost při zapalování tabákových výrobků (sirky,
zapalovače).
`}</p>
      <p>{`c) nekouřit v objektech a prostorách, které jsou označeny
zákazovou tabulkou „Zákaz kouření“.
`}</p>
      <p>{`d) nepoškozovat instalované bezpečnostní značení.
`}</p>
      <p>{`e) nepoškozovat ani žádným způsobem neomezovat použití
instalovaných přenosných hasicích přístrojů.
`}</p>
      <p>{`f) nepoškozovat ani žádným způsobem neomezovat použití
hydrantových systémů ani dalších požárně bezpečnostních
zařízení.
`}</p>
      <p>{`g) nepoškozovat a žádným způsobem nezasahovat do
instalovaných elektrických zařízení.
`}</p>
      <p>{`h) nepoškozovat ani žádným způsobem neomezovat použití
ovládacích prvků instalovaných technických zařízení
(rozvodné skříně elektrických zařízení, hlavní vypínače,
hlavní uzávěry vody apod.).
`}</p>
      <p>{`i) při vyhlášení požárního poplachu se řídit pokyny
organizátora akce, členů preventivní požární hlídky, členů
ostrahy, popř. členů zasahujících složek integrovaného
záchranného systému.`}</p>
      <h3>{"4. ODPOVĚDNOST ZA ŠKODU"}</h3>
      <p>
        {`4.1 Pořadatel neodpovídá za škodu na majetku či zdraví
účastníků festivalu, pokud není tato škoda zaviněná
porušením právních předpisů pořadatelem a v přímé
souvislosti s tímto porušením. Odpovědnost za škodu
pořadatele je omezena částkou odpovídající maximálně ceně
vstupenky zakoupené účastníkem.
`}
      </p>
      <p>{`4.2 Návštěvníci, zejména starší nebo postižení tělesnou
vadou, se musí ve vlastním zájmu pohybovat opatrně na
hladkých a nerovných plochách a vyvarovat se úrazu.
Pořadatel neodpovídá za újmu způsobenou úrazem, který si
návštěvník způsobil vlastní neopatrností nebo
nedodržováním návštěvního řádu.
`}</p>
      <p>{`4.3 Návštěvníci jsou povinni odevzdat provozovateli a/nebo
provozovatelem pověřené osobě a/nebo bezpečnostní službě
všechny předměty nalezené v areálu festivalu KVĚTENLES
2022.
V případě, že návštěvníci v areálu festivalu KVĚTENLES
2022 naleznou jakékoliv podezřelé předměty, či předměty, jež
jsou způsobilé ohrozit majetek, životy a/nebo zdraví třetích
osob, jsou povinni bezodkladně informovat provozovatele
a/nebo provozovatelem pověřené osoby, bezpečnostní
službu. Každý návštěvník odpovídá provozovateli a/nebo
jakékoliv třetí osobě za újmu vzniklou v důsledku jeho
porušení pravidel vyplývajících z tohoto návštěvního řádu
a/nebo jiných závazných právních předpisů.
`}</p>
      <p>{`4.4 Provozovatel je oprávněn požadovat na návštěvníkovi
vydání věci, kterou byla způsobena provozovateli a/nebo
jakékoliv třetí osobě úraz a/nebo újma.
`}</p>
      <p>{`4.5 Návštěvníci jsou povinni informovat provozovatele a/nebo
bezpečnostní službu o jakýchkoliv úrazech a/nebo vzniku
újmy v areálu festivalu KVĚTENLES 2022.
`}</p>
      <p>
        {`4.6 V případě evakuace areálu festivalu KVĚTENLES 2022 či
jiných mimořádných situací jsou návštěvníci povinni
dodržovat „Požární poplachovou směrnici a evakuační řád
areálu, a jsou zároveň povinni řídit se pokyny provozovatele
a/nebo provozovatelem pověřených osob, bezpečnostní
služby a/nebo složek integrovaného záchranného systému.
Požární poplachová směrnice tvoří přílohu č. 1 tohoto
návštěvního řádu a je jeho nedílnou součástí.`}
      </p>
      <h3>{"5. OSTATNÍ USTANOVENÍ"}</h3>
      <p>
        {`5.1 Pořadatel zpracovává některé osobní údaje návštěvníků
jakožto správce osobních údajů ve smyslu čl. 4 odst. 7
Nařízení Evropského parlamentu a Rady (EU) č. 2016/679,
tzv. Obecného nařízení o ochraně osobních údajů (dále jen
„Nařízení“). Komplexní informace o zpracování osobních
údajů v rámci festivalu KVĚTENLES 2022 ve smyslu čl. 13
Nařízení, včetně informace o účelu jednotlivých činností
zpracování, jejich právních základech, příjemcích údajů a
právech návštěvníka jakožto subjektu údajů jsou uvedeny v
samostatné příloze tohoto návštěvního řádu, která je
dostupná prostřednictvím internetových stránek
www.kvetenles-festival.cz a v místě konání festivalu.
`}
      </p>
      <p>{`5.2 Vstupem do areálu návštěvníci areálu festivalu
KVĚTENLES 2022 souhlasí, bez dalšího upozornění, s
bezplatným využitím svého obrazu nebo podobizny jako
součásti jakéhokoliv obrazového záznamu, přenosu či
produkce akce pořádané v areálu festivalu KVĚTENLES
2022 pro komerční nebo propagační účely ve smyslu § 84
zák. č. 89/2012 Sb., občanského zákoníku (dále jen
“občanský zákoník”), a to v rámci zobrazení celé nebo části
akce pořádané v areálu festivalu KVĚTENLES 2022
pořadatelem a/nebo jeho smluvními partnery. Souhlas s
využitím obrazu nebo podobizny dle tohoto ustanovení je
návštěvníky pořadateli a jeho smluvním partnerům udělován
na dobu neurčitou, přičemž návštěvník je oprávněn tento
souhlas odvolat, přičemž neodůvodňuje-li toto odvolání
podstatná změna okolností nebo jiný rozumný důvod,
odpovídá návštěvník pořadateli ve smyslu § 87 odst. 2
občanského zákoníku za škodu, která mu v důsledku
odvolání souhlasu vznikne. Zpracování osobních údajů v
rámci používání obrazových záznamů návštěvníků se řídí
informací o zpracování osobních údajů dle předchozího
odstavce.
`}</p>
      <p>
        {`5.3 Návštěvníci festivalu KVĚTENLES 2022 dále berou na
vědomí, že v areálu festivalu KVĚTENLES 2022 mohou být
pořizovány zvukové a/nebo obrazové záznamy pro účely
tiskového, rozhlasového, televizního nebo obdobného
zpravodajství. Návštěvníci berou na vědomí, že k pořizování
těchto záznamů není ve smyslu § 89 občanského zákoníku
zapotřebí jejich souhlasu, jsou-li tyto záznamy použity
přiměřeným způsobem a není-li toto použití v rozporu s jejich
oprávněnými zájmy.`}
      </p>
      <h3>{"6. ZÁVĚREČNÁ USTANOVENÍ"}</h3>
      <p>
        {`6.1 Pravidla stanovená v tomto návštěvním řádu mohou být
dále doplňována pravidly vztahujícími se k jednotlivým akcím
pořádaným v areálu festivalu KVĚTENLES 2022. Tato
doplňující pravidla budou vždy vhodně uveřejněna v areálu
festivalu KVĚTENLES 2022 či k dispozici u provozovatele
nebo u bezpečnostní služby, a návštěvníci jsou povinni je
dodržovat.
`}
      </p>
      <p>{`6.2 Tento návštěvní řád nabývá účinnosti dne 26.05.2022.
`}</p>
      <p>{`6.3 Práva a povinnosti výslovně neupravené v tomto
návštěvním řádu se řídí obecně platnými právními předpisy,
zejména zák. č. 89/2012 Sb., občanským zákoníkem, v
platném a účinném znění.
`}</p>
      <p>
        {`6.4 Pořadatel si vyhrazuje právo kdykoliv změnit tento
návštěvní řád.
`}
      </p>
      <p>
        <b>v Chomutově dne 2. prosince 2022. v.r.</b>
      </p>
      <p>
        <b>ArtCulture s.r.o.</b>
      </p>
    </div>
  </div>
);

export default Rules;
